import maplibregl from 'maplibre-gl' // or "const maplibregl = require('maplibre-gl');"
import MaplibreGeocoder from '@maplibre/maplibre-gl-geocoder'

const map = new maplibregl.Map({
  container: 'map',
  style: 'https://tiles.kartensachen.de/styles/dark2/style.json',
  center: [11.41142, 53.62346],
  zoom: 14.84
})

var geocoder_api = {
  forwardGeocode: async config => {
    const features = []
    try {
      let request =
        'https://nominatim.openstreetmap.org/search?q=' +
        config.query +
        '&format=geojson&polygon_geojson=1&addressdetails=1'
      const response = await fetch(request)
      const geojson = await response.json()
      for (let feature of geojson.features) {
        let center = [
          feature.bbox[0] + (feature.bbox[2] - feature.bbox[0]) / 2,
          feature.bbox[1] + (feature.bbox[3] - feature.bbox[1]) / 2
        ]
        let point = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: center
          },
          place_name: feature.properties.display_name,
          properties: feature.properties,
          text: feature.properties.display_name,
          place_type: ['place'],
          center: center
        }
        features.push(point)
      }
    } catch (e) {
      console.error(`Failed to forwardGeocode with error: ${e}`)
    }

    return {
      features: features
    }
  }
}
map.addControl(
  new MaplibreGeocoder(geocoder_api, {
    maplibregl: maplibregl
  })
)

map.addControl(new maplibregl.NavigationControl({}))

map.addControl(
  new maplibregl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true
    },
    trackUserLocation: false,
    showUserLocation: false,
    showAccuracyCircle: false
  })
)

var center = map.getCenter()
var marker = new maplibregl.Marker({
  element: document.getElementById('shirt'),
  draggable: false
})
  .setLngLat(center)
  .addTo(map)

function updateMarkerPosition () {
  var center = map.getCenter()
  marker.setLngLat(center)
}
updateMarkerPosition()

map.on('move', function () {
  console.log('A moveend event occurred.')
  updateMarkerPosition()
})
